import {useEffect, useState} from "react"
import axios from "axios"
import {Box, Button, FormControlLabel, Stack, Switch, Tooltip, Typography} from "@mui/material"

export default function ConfigurePermissions({member, setMemberToModify, isAddingNewMember, snackbar, resetModal, setNotePermissions, setSubmitCallback}) {
	const [read, setRead] = useState(true) // is there any reason read would ever be set to false??
	const [write, setWrite] = useState(false)
	const [admin, setAdmin] = useState(false)
	const [isFreeTier, setIsFreeTier] = useState(false)

	useEffect(() => {
		checkIsFreeTier()
		if(!isAddingNewMember && member !== null){
			setRead(member.read)
			setAdmin(member.admin)
			setWrite(member.write)
		}
	}, [])

	useEffect(() => {
		if(!isAddingNewMember && isFreeTier) {
			setWrite(false)
		}
	}, [isFreeTier])

	useEffect(() => {
		if (admin && !write) {
			setWrite(true)
		}

		if (write && !read) {
			setRead(true)
		}

		if(member){
			setMemberToModify(prevState => ({
				...prevState,
				admin,
				write,
				read
			}))
		}
	}, [admin, write, read])

	const handleNotePermissions = () => {
		setNotePermissions(true)
		setSubmitCallback(() => handleSubmit)
	}

	const handleSubmit = (selectedNotes = []) => {
		const selectedNoteIds = {}
		for(let [noteId, type] of Object.entries(selectedNotes)) {
			selectedNoteIds[noteId] = type
		}
		// console.log("submitting form - selected note ids ", selectedNotes.map(note => note.id))
		if(isAddingNewMember) {
			axios
				.post(`/api/workspace/${member.workspaceId}/user/${member.userId}`, {
					admin,
					write,
					read,
					selectedNoteIds
				})
				.then(() => resetModal())
				.catch((e) => {
					if(e.response.status === 401 && e.response.data){
						snackbar(e.response.data, {variant: "error"})
					} else {
						console.error("ADDING NEW WORKSPACE MEMBER & PERMISSIONS: \n", e)
						snackbar(`Failed to add a new member to the workspace. Reason: ${e}`, {variant: "error"})
					}
				})
		} else {
			axios
				.put(`/api/workspace/${member.workspaceId}/user/${member.userId}`, {
					admin,
					write,
					read,
					selectedNoteIds
				})
				.then(() => resetModal())
				.catch((e) => {
					if(e.response.status === 514){
						snackbar(e.response.statusText, {variant: "error"})
					} else if (e.response.status === 401 && e.response.data){
						snackbar(e.response.data, {variant: "error"})
					} else {
						console.error("MODIFYING WORKSPACE MEMBER PERMISSIONS: \n", e)
						snackbar(`Failed to modify workspace member's permissions. Reason: ${e}`, {variant: "error"})
					}
				})
		}
	}

	const checkIsFreeTier = () => {
		axios.get(`/api/workspace/${member.workspaceId}/member/${member.userId}/is-free`)
			.then(({data}) => {
				setIsFreeTier(data)
				return data
			})
			.catch((e) => {
				console.error("SUBSCRIPTION PERMISSIONS CHECK DID NOT RETURN OK: ", e)
				setIsFreeTier(false)
			})
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<Typography id="modal-modal-title" variant="h4">
				Configure Permissions
			</Typography>
			<Box sx={{ margin: "10px" }}>
				{
					member !== null &&
					<Stack>
						<Box sx={{ textAlign: "center" }}>
							<Typography variant={"subtitle2"}>
								{member.user.firstName} {member.user.lastName}{" "}
							</Typography>
							<Typography variant={"caption"}>( {member.user.email} )</Typography>
						</Box>
						<br />
						<Box
							sx={{ margin: "auto", display: "flex", flexDirection: "column" }}
						>
							<FormControlLabel
								control={
									<Switch
										checked={read}
										onChange={(e) => setRead(e.target.checked)}
										name="Read"
									/>
								}
								label="Read Access"
							/>
							<Tooltip
								title={isFreeTier && "Free users cannot write or admin workspaces they don't own"}
								placement={"right"}
								arrow
							>
								<span>
									<FormControlLabel
										control={
											<Switch
												checked={write}
												onChange={(e) => setWrite(e.target.checked)}
												name="Write"
												disabled={isFreeTier}
											/>
										}
										label="Write Access"
									/>
								</span>
							</Tooltip>
							<Tooltip
								title={isFreeTier && "Free users cannot write or admin workspaces they don't own"}
								placement={"right"}
								arrow
							>
								<span>
									<FormControlLabel
										control={
											<Switch
												checked={admin}
												onChange={(e) => setAdmin(e.target.checked)}
												name="Admin"
												disabled={isFreeTier}
											/>
										}
										label="Admin Access"
									/>
								</span>
							</Tooltip>
						</Box>
						{ (!member.admin && admin) &&
							<Typography variant={"body2"} color={ "alertRed.mainTransparency100" }>
								Warning: Giving a user admin access to this workspace will make it impossible for you to remove them from this workspace, and will give them full management permissions. This action is irreversible and permission is irrevocable without contacting support.
							</Typography>
						}
					</Stack>
				}
			</Box>
			<br />
			<Box sx={{ display: "flex", justifyContent:"space-evenly" }}>
				{member?.admin || (!member?.admin && admin) ?
					<Button
						variant={"outlined"}
						sx={{marginRight: "10px"}}
						onClick={() => handleSubmit([])}
					>
						Submit
					</Button>
					:
					<Button
						variant={"outlined"}
						sx={{marginRight: "10px"}}
						onClick={handleNotePermissions}
					>
						Next
					</Button>
				}
				<Button
					variant={"outlined"}
					color={"error"}
					onClick={resetModal}
				>
					Cancel
				</Button>
			</Box>
		</Box>
	)
}