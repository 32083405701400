import {Box, Typography, Button, List, ListItem} from "@mui/material"
import { useEffect, useState } from "react"
import NoteRestrictionBreadcrumb from "./NoteRestrictionBreadcrumb"
import { selectWorkspaceId } from "../../../reducers/workspaceSlice"
import { useSelector } from "react-redux"
import axios from "axios"
import { useSnackbar } from "notistack"


export default function ConfigureNotePermissions({selectedNotes, setSelectedNotes, isAddingNewMember, setNotePermission, submitCallback, member, resetModal, authorInviteLink}) {
	const [topLevelNotes, setTopLevelNotes] = useState([])
	const isReadOnly = member && member.write === false
	const workspaceId = useSelector(selectWorkspaceId)
	const { enqueueSnackbar } = useSnackbar()
	const fetchTopLevelNotes = async () => {
		if (isAddingNewMember) {
			axios
				.get(`/api/workspace/${workspaceId}/note/0/children`)
				.then(({ data: notes }) => {
					setTopLevelNotes(notes)
				})
				.catch((err) => {
					console.error("Workspace notes request failed: ", err)
					enqueueSnackbar("Could not retrieve Workspace Notes", {
						variant: "error",
					})
				})
		} else {
			// get current member's permissions
			// request to "/:id/user/:userId/restrictions"
			// set selected notes to the ones returned
			try {
				const {data: topLevelNotes} = await axios.get(`/api/workspace/${workspaceId}/note/0/children`)
				const {data: existingRestrictions} = await axios.get(`/api/workspace/${workspaceId}/user/${member.userId}/restrictions`)
				setTopLevelNotes(topLevelNotes)
				if(Object.keys(existingRestrictions).length < 1) {
					const fullAccessNotes = topLevelNotes.reduce((acc, note) => {
						acc[note.id] = "edit"
						return acc
					}, {})
					setSelectedNotes(fullAccessNotes)
				} else {
					setSelectedNotes(existingRestrictions)
				}
			} catch(e) {
				enqueueSnackbar("Could not retrieve existing note restrictions", {
					variant: "error",
				})
			}
		}
	}

	const selectAllNotesAsEdit = () => {
		const fullEditNotes = topLevelNotes.reduce((acc, note) => {
			acc[note.id] = "edit"
			return acc
		}, {})
		setSelectedNotes(fullEditNotes)
	}

	const selectAllNotesAsRead = () => {
		const fullReadNotes = topLevelNotes.reduce((acc, note) => {
			acc[note.id] = "read"
			return acc
		}, {})
		setSelectedNotes(fullReadNotes)
	}

	const copyInviteLink = (inviteLink) => {
		navigator.clipboard
			.writeText(inviteLink)
			.then(() => {
				enqueueSnackbar("Author invite link copied!", {variant: "success", autoHideDuration: 3000})
			})
			.catch((e) => {
				enqueueSnackbar("Failed to copy author invite link", {variant: "error", autoHideDuration: 3000})
				console.error ("ERROR WRITING LINK TO CLIPBOARD: ", e)
			})
	}

	useEffect(() => {
		fetchTopLevelNotes()
	}, [])

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				gap: "2rem"
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
					gap: "1rem"
				}}
			>
				<Typography id="modal-modal-title" variant="tunedH1">
					Visible Notes
				</Typography>
				<Typography id="modal-modal-subtitle" variant="tunedSubtitle2" sx={{textAlign: "left"}}>
					Select top level notes that you want visible to the user. New notes created in this workspace will note be visible.
				</Typography>
				<Box sx={{display: "flex", gap: "2rem", justifyContent: "space-between", width: "100%"}}>
					<Button
						sx={{ width: "100%", fontSize: "1rem", lineHeight: "1.18rem", paddingY: "12px", minWidth: "fit-content" }}
						variant={"outlined"}
						onClick={() => setSelectedNotes({})}
					>
						Select None
					</Button>
					<Button
						sx={{ width: "100%", fontSize: "1rem", lineHeight: "1.18rem", paddingY: "12px", minWidth: "fit-content" }}
						variant={"outlined"}
						onClick={selectAllNotesAsRead}
					>
						Select All as Viewable
					</Button>
					{ isReadOnly === false && (
						<Button
							sx={{ width: "100%", fontSize: "1rem", lineHeight: "1.18rem", paddingY: "12px", minWidth: "fit-content" }}
							variant={"outlined"}
							onClick={selectAllNotesAsEdit}
						>
							Select All as Editable
						</Button>
					)}
				</Box>
			</Box>
			<Box sx={{
				display: "flex",
				gap: "0.5rem",
				alignItems: "center",
				width: "100%",
				paddingRight: "2.5rem",
				justifyContent: "flex-end",
				marginBottom: "-2rem",
				backgroundColor: "none",
			}}>
				<Typography variant={"tunedSubtitle2"} sx={{fontSize: "0.95em", lineHeight: "1rem", color: "action.active"}}>
					View
				</Typography>
				<Typography variant={"tunedSubtitle2"} sx={{fontSize: "0.95em", lineHeight: "1rem", color: "action.active"}}>
					Edit
				</Typography>
			</Box>
			{/* Top Level Note List */}
			<List
				sx={{
					maxHeight: "25vh",
					overflow: "auto",
					width: "100%",
					padding: "8px 0.5rem"
				}}
			>
				{
					topLevelNotes.map((note, i) => {
						return (
							
							<ListItem
								key={i}
								sx={{
									padding: "0px",
									paddingBottom: "0.75rem",
									display: "block",
									pointerEvents: "auto",
								}}>
								<NoteRestrictionBreadcrumb note={note} selectedNotes={selectedNotes} setSelectedNotes={setSelectedNotes} readonly={isReadOnly}/>
							</ListItem>
						)
					})
				}
			</List>
			{authorInviteLink.length > 1 &&
				<Box>
					<Typography paragraph>Link for author invite (click to copy link to clipboard): </Typography>
					<Typography paragraph> {encodeURI(authorInviteLink)} </Typography>
					<Box sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						gap: "1rem"
					}}>
						<Button onClick={() => copyInviteLink(encodeURI(authorInviteLink))} variant={"outlined"}>
							Copy invite link
						</Button>
						<Button
							onClick={() => window.location = `mailto:${member?.user.email}?body=${encodeURI(authorInviteLink)}`}
							variant={"outlined"}
							color={"success"}
						>
							Open e-mail client
						</Button>
					</Box>
				</Box>
			}
			{/* Buttons */}
			<Box sx={{display: "flex", gap: "2rem", justifyContent: "flex-end", width: "100%"}}>
				<Button
					onClick={() => {
						submitCallback && submitCallback(selectedNotes)
					}}
					variant={"contained"}
					color={"primary"}
					disabled={Object.keys(selectedNotes).length === 0 || authorInviteLink.length > 1}
				>
					{isAddingNewMember && member?.userId > 0 ?
						"Add Member"
						:
						member?.userId === -1 ?
							"Generate Link"
							:
							"Save Changes"
					}
				</Button>
				{authorInviteLink.length > 1 ?
					(<Button
						color={"success"}
						onClick={() => {
							setSelectedNotes([])
							setNotePermission(false)
							resetModal()
						}}
					>
						Done
					</Button>)
					:
					(<Button
						color={"error"}
						onClick={() => {
							setSelectedNotes([])
							setNotePermission(false)
							resetModal()
						}}
					>
						Cancel
					</Button>)
				}
			</Box>
		</Box>
	)
}