import { Card, Tooltip, IconButton, Typography } from "@mui/material"
import { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setOnDrop } from "../../../reducers/articleDragSlice"
import { selectTargetNote } from "../../../reducers/targetNoteSlice"
import { selectMovingNote } from "../../../reducers/moveNoteSlice"
import { NOTE_ACTIONS, selectNoteAction } from "../../../reducers/noteActionSlice"
import EditNote from "./EditNote"
import { fetchNoteCitationsAndSwitchPanels } from "../../../reducers/noteArticleTabsV2Slice"
import { smoothScrollTo } from "../../../utils/domUtilities"
import AddNote from "./AddNote"
import { selectCopyNote } from "../../../reducers/copyNoteSlice"
//import AddNote from "./AddNote"

const Note = ({note, index}) => {
	const node = useRef(null)
	const [isDragHover, setIsDragHover] = useState(false)
	const dispatch = useDispatch()
	const targetNote = useSelector(selectTargetNote)
	const movingNote = useSelector(selectMovingNote)
	const noteAction = useSelector(selectNoteAction)
	const copyNote = useSelector(selectCopyNote)
	const isCopyOrMovingNote = movingNote?.value?.id === note?.id || copyNote.value.id === note?.id
	const noteInTargetNotePath = targetNote.path.indexOf(note?.id) !== -1
	const noteIsSelected = targetNote.id == note?.id
	//TODO: 1-8-24 DK reported a bug where the log line below this appeared to spit out a value of '-1' before breaking the auto-scrolling in the left panel, causing the panel to "jump".
	console.log(copyNote)

	const handleDrop = (event) => {
		event.preventDefault()
		dispatch(setOnDrop({note}))
		setIsDragHover(false)
	}

	const handleDragOver = (event) => {
		event.preventDefault() 
		if (!isDragHover ) {
			setIsDragHover(true)
		}
	}

	const handleDragLeave = (event) => {
		event.preventDefault()
		setIsDragHover(false)
	}

	// PAGE IN PARAMS
	const openArticles = ({singleNote}) => {
		dispatch(fetchNoteCitationsAndSwitchPanels({
			note, 
			singleNote
		}))
	}

	useEffect(() => {
		if (noteAction.action === NOTE_ACTIONS.scrollToNote) {
			if (noteAction.noteId === note?.id) {
				const panelScroll = document.getElementById("panel-scroll")
				const rect = node.current.getBoundingClientRect()
				const panelRect = panelScroll.getBoundingClientRect()
				const topPosition = (rect.top - panelRect.top) + panelScroll.scrollTop - 128
				setTimeout(() => {
					smoothScrollTo(panelScroll, topPosition, 125)
				}, 200)
			}
		}
	}, [noteAction, dispatch, note, note?.loadingChildren])

	const dragClasses = () => {
		if (noteIsSelected) {
			return "isOverTargetNote"
		}
		if (isCopyOrMovingNote) {
			return "isOverMovingNote"
		}

		return "isOverNote"
	}

	return(
		<div
			id={`note-${note?.id}-div-wrapper`}
			style={{
				alignItems: "center",
				width: "100%",
				marginRight: "10px"
			}}
		>
			{ noteAction.action === NOTE_ACTIONS.addSibling && noteAction.noteId === note?.id && (
				<AddNote note={note} />
			)}
			<Card
				ref={node}
				id={`note-${note?.id}-card-wrapper`}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				variant={"note"}
				className={isDragHover ? dragClasses() : "notOver"}
				sx={{
					display: "flex",
					alignItems: "center",
					width: "100%",
					backgroundColor: "white",
					borderLeftWidth: "5px",
					borderRightWidth: "5px",
					borderLeftStyle: "solid",
					borderRightStyle: "solid",
					borderLeftColor: "white",
					borderRightColor: "white",
					...(noteInTargetNotePath && !isCopyOrMovingNote && {
						borderLeftColor: "primary.main",
						borderRightColor: "primary.main",
					}),
					...(noteIsSelected && {
						borderLeftColor: "primary.main",
						borderRightColor: "primary.main",
						backgroundColor: "notes.selected",
					}),
					...(isCopyOrMovingNote && {
						backgroundColor: "notes.moveNoteIndicator",
						borderLeftColor: "notes.moveNoteIndicatorAccent",
						borderRightColor: "notes.moveNoteIndicatorAccent",
					}),
					"&:hover": {
						borderLeftColor: isCopyOrMovingNote
							? "notes.moveNoteIndicatorAccent"
							: (noteIsSelected || noteInTargetNotePath
								? "primary.main"
								: "notes.hoverBg"
							),
						backgroundColor: isCopyOrMovingNote
							? "notes.moveNoteIndicator"
							: (targetNote.id === note?.id
								? "notes.selected"
								: "notes.hoverBg"
							),
						borderRightColor: isCopyOrMovingNote
							? "notes.moveNoteIndicatorAccent"
							: (noteIsSelected || noteInTargetNotePath
								? "primary.main"
								: "notes.hoverBg"
							),
					},
				}}
			>
				{noteAction.action === NOTE_ACTIONS.editNote && noteAction.noteId === note?.id ? (
					<EditNote note={note} index={index} />
				) : (
					<Typography
						variant={"body1"}
						sx={{
							padding: "5px 10px",
							marginBottom: 0,
							wordBreak: "break-word"
						}}
						paragraph
					>
						{note?.content}
					</Typography>
				)}
				<span style={{ flexGrow: 1 }} />
				{
					note?.childCount > 0 && !!(note?.articleNoteCount + note?.attachmentNoteCount) && (
						<Tooltip title={"Open Articles"} placement={"left"}>
							<IconButton
								onClick={() => openArticles({singleNote: true})}
								color="tertiary"
								sx={{
									alignSelf: "stretch",
									width: "50px",
									height: "auto",
									justifyContent: "end",
									alignItems: "center",
									color: "orange",
									flexGrow: 0,
									"&:hover": {
										color: "primary.main",
									},
								}}
							>
								<Typography
									variant={"body1"}
									sx={{
										padding: "4px 8px",
									}}>
									{note?.articleNoteCount + note?.attachmentNoteCount}</Typography>
							</IconButton>
						</Tooltip>
					)
				}
				<Tooltip title={"Open Articles"} placement={"right"}>
					<IconButton
						onClick={() => openArticles({singleNote: false})}
						color={"primary"}
						sx={{
							alignSelf: "stretch",
							width: "50px",
							height: "auto",
							justifyContent: "end",
							alignItems: "center",
							flexGrow: 0,
						}}
					>
						<Typography
							variant={"body1"}
							sx={{
								padding: "4px 8px"
							}}>
							{note?.uniqueCount + note?.attachmentCount}</Typography>
					</IconButton>
				</Tooltip>
			</Card>
			{ noteAction.action === NOTE_ACTIONS.addChild && noteAction.noteId === note?.id && (
				<AddNote note={note} />
			)}
		</div>
	)
}

export default memo(Note)