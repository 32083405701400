import FileCopyIcon from "@mui/icons-material/FileCopy"
import {Box, Button, Card, Typography} from "@mui/material"
import { formatDateWithYear } from "../../../utils/dates"
import BookIcon from "@mui/icons-material/Book"

export default function NotificationListItem({sharedCitationObject, clickHandler}) {

	return (
		<>
			<Card
				elevation={0}
				variant={"note"}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					height: "120px",
					padding: "16px",
					borderRadius: "0px",
					marginBottom: "2px",
					boxSizing: "border-box",
					"&:hover": {
						boxShadow: "0 0px 8px rgba(0, 0, 0, 0.7)",
					},
					position: "relative",
					backgroundColor: sharedCitationObject.isRead ? "#EAEAEA" : "white",
				}}
			>
				<div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
					<Box sx={{backgroundColor: "primary.main", borderRadius: "100%", padding: "1rem"}}>
						{
							sharedCitationObject.type === "article_share" && (
								<FileCopyIcon sx={{color: "white"}} />
							)
						}
						{
							sharedCitationObject.type === "workspace_invite" && (
								<BookIcon sx={{color: "white"}} />
							)
						}
					</Box>
					{
						sharedCitationObject.type === "article_share" && (
							<div>
								<div>
									<Typography variant="accentedText">{sharedCitationObject.userName}</Typography>
									{" "} shared {" "}
									<Typography variant="accentedText">{sharedCitationObject?.articles?.length} article{sharedCitationObject?.articles?.length > 1 ? "s" : ""}</Typography>
									{" "} with you
								</div>
								<div style={{color: "grey"}}>
									"{sharedCitationObject.message}"
								</div>
							</div>
						)
					}
					{
						sharedCitationObject.type === "workspace_invite" && (
							<div>
								<div>
									<Typography variant="accentedText">{sharedCitationObject.userName}</Typography>
									{" "}
									invited you to the
									{" "}
									<Typography variant="accentedText">{sharedCitationObject.sendingWorkspace.title}</Typography>
									{" "
									}workspace
								</div>
							</div>
						)
					}

					
				</div>
				<Button
					onClick={() => clickHandler(sharedCitationObject)}
					variant={"text"}
					color={"otherInfo"}
					discreteOpen
					sx={{
						backgroundColor: "none",
					}}
				>
					{ sharedCitationObject.type === "workspace_invite" && "Go To Workspace"}
					{ sharedCitationObject.type === "article_share" && "View Articles"}
				</Button>
				<span style={{color: "grey", minWidth: "20%"}}>
					{formatDateWithYear(sharedCitationObject.date)}
				</span>
			</Card>
		</>
	)
}